import Head from 'next/head';
import { useCallback } from 'react';
import { useIdleCallback } from '@shopify/react-idle';

import { handleError } from '@/lib/handleError';
import { TextPage } from '@/layouts';
import ecommerce from '@/seo/Ecommerce';
import { getCategoryTreeBySlug } from '@fh-components/fh-js-api-core/store/slices/categoriesSlice';
import Router from 'next/router';

export default function NextHome(props) {
	const setPageType = useCallback(() => {
		ecommerce.setPageType(ecommerce.PAGE_TYPES.HOME);
	}, []);

	useIdleCallback(setPageType);

	return (
		<>
			<Head>
				<title>Главная</title>
			</Head>

			<TextPage isMain {...props} />
		</>
	);
}

NextHome.getInitialProps = async ({ reduxStore }) => {
	let data;

	await reduxStore.dispatch(
		getCategoryTreeBySlug({
			slug: `/`,
			onSuccess: result => {
				if (result.page.pageType === 'redirect') {
					Router.push(result.page.data.to);
				}
				data = result.page.data.page;
			},
			onError: result => {
				handleError(result);
			},
		}),
	);

	return { data };
};
